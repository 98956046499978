import React from 'react'
import appleLogo from '../images/apple.svg'
import googlePlayLogo from '../images/google-play.svg'
import { Link } from 'gatsby'

const socialButton = {
    backgroundColor: '#2d025b',
    width: 150,
    height: 40,
    display: 'flex',
    padding: 5,
    borderRadius: 20,
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 10px 10px 0px',
    fontWeight: 'bold'
}

const normalButton = {
    fontSize: 14,
    backgroundColor: '#00cba2',
    height: 40,
    display: 'flex',
    padding: '5px 10px',
    borderRadius: 20,
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 10px 10px 0px',
    fontWeight: 'bold'
}

const whiteButton = {
    fontSize: 14,
    height: 40,
    display: 'flex',
    padding: '5px 10px',
    borderRadius: 20,
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 10px 10px 0px',
    fontWeight: 'bold'
}

const DownloadApps = ({ withoutLinks }) => {
    return (
        <div className="download-apps">
            {
                withoutLinks == null ?
                    <div className="nav-links">
                    
                    </div> : null
            }
            <div className="nav-download">
                <a style={socialButton} href="https://onelink.to/47atrw" target="_blank">
                    <img src={appleLogo} alt="apple store" style={{ width: 20 }} />
                    <div style={{ fontSize: 10, marginLeft: 10 }}>
                        <div>
                            Available on the
                        </div>
                        <span className="big-txt">App Store</span>
                    </div>
                </a>

                <a style={socialButton} href="https://onelink.to/47atrw" target="_blank">
                    <img src={googlePlayLogo} alt="google play" style={{ width: 20 }} />
                    <div style={{ fontSize: 10, marginLeft: 10 }}>
                        <div>
                            Get it on
                        </div>
                        <span className="big-txt">Google Play</span>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default DownloadApps;